<template>
  <b-card>
    <b-row>
      <b-col cols="12">
        <title-name />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <tax-no />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <tax-office />
      </b-col>
      <b-col
        cols="12"
      >
        <address-text />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <used-reports />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <usable-reports />
      </b-col>
      <b-col
        cols="12"
      >
        <package-end />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol } from 'bootstrap-vue'
import TitleName from '@/views/Admin/Dealers/elements/Title.vue'
import TaxNo from '@/views/Admin/Dealers/elements/TaxNo.vue'
import TaxOffice from '@/views/Admin/Dealers/elements/TaxOffice.vue'
import AddressText from '@/views/Admin/Dealers/elements/Address.vue'
import UsedReports from '@/views/Admin/Dealers/elements/UsedReports.vue'
import UsableReports from '@/views/Admin/Dealers/elements/UsableReports.vue'
import PackageEnd from '@/views/Admin/Dealers/elements/PackageEnd.vue'

export default {
  name: 'ItemForm',
  components: {
    BCard,
    BRow,
    BCol,
    TitleName,
    TaxNo,
    TaxOffice,
    AddressText,
    UsedReports,
    UsableReports,
    PackageEnd,
  },
}
</script>
