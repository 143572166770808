<template>
  <b-form-group
    label="Kullanım Limiti"
    label-for="usable_reports"
  >
    <b-form-input
      id="usable_reports"
      v-model="dataItem.usable_reports"
      placeholder="Kullanım Limiti"
    />
  </b-form-group>
</template>

<script>
import {
  BFormGroup, BFormInput,
} from 'bootstrap-vue'

export default {
  name: 'UsableReports',
  components: {
    BFormGroup,
    BFormInput,
  },
  computed: {
    dataItem() {
      return this.$store.getters['adminDealers/dataItem']
    },
  },
}
</script>
