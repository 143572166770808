<template>
  <b-form-group
    label="Bitiş Tarihi"
    label-for="package_end"
  >
    <b-form-datepicker
      id="package_end"
      v-model="dataItem.package_end"
      v-bind="{labelNoDateSelected: 'Başlangıç',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
      locale="tr"
      start-weekday="1"
    />
  </b-form-group>
</template>

<script>
import {
  BFormGroup, BFormDatepicker,
} from 'bootstrap-vue'

export default {
  name: 'PackageEnd',
  components: {
    BFormGroup,
    BFormDatepicker,
  },
  computed: {
    dataItem() {
      return this.$store.getters['adminDealers/dataItem']
    },
  },
}
</script>
